export const HOME = "/"

//LEGAL
export const PRIVACY = "/legal/privacy"
export const TERMS = "/legal/terms"

// ABOUT
export const BLOG = "/blog"
export const PRESS = "/press"

//GUIDELINES
export const GUIDELINES = "/guidelines"

//FAQ
export const FAQ_HOME = "/faq"
