/** @jsx jsx */
import { jsx, Image } from "theme-ui"

import amootoLogo from "../../images/amooto-logo.svg"

function Logo(props) {
  const { size = [20] } = props
  return (
    <Image
      src={amootoLogo}
      alt="Amooto logo"
      sx={{ height: size, width: "auto" }}
    />
  )
}

export default Logo
