/** @jsx jsx */
import { useMemo, useCallback } from "react"
import { jsx, Flex, Box, Text, Link } from "theme-ui"
import { Link as GatsbyLink, useStaticQuery, graphql } from "gatsby"

function Footer() {
  const {
    menu: { primary, secondary },
  } = useStaticQuery(query)

  const linksObj = useMemo(() => {
    const links = {
      1: primary,
      2: secondary,
    }
    const obj = {}
    Object.keys(links).forEach(d => {
      obj[d] = links[d].filter(item => item.hide !== true)
    })
    return obj
  }, [primary, secondary])

  const renderNavRow = useCallback(
    key => {
      return (
        <Flex
          sx={{
            width: "100%",
            textAlign: "center",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {linksObj[key].map((item, i) => {
            return (
              <Link
                as={item.uri.startsWith("http") ? "a" : GatsbyLink}
                sx={{
                  textTransform: "capitalize",
                  lineHeight: 2.3,
                  textDecoration: "none",
                  fontSize: [14],
                  color: "charCoal",
                }}
                key={item.title}
                to={item.uri}
                href={item.uri}
              >
                {item.title}
                {i - linksObj[key].length !== -1 && (
                  <Text px={[1, 2]} sx={{ color: "orange" }}>
                    ・
                  </Text>
                )}
              </Link>
            )
          })}
        </Flex>
      )
    },
    [linksObj]
  )

  return (
    <Box mt={4} sx={{ textAlign: "center" }}>
      <Flex
        px={5}
        py={4}
        mb={[2, 3]}
        sx={{
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        {renderNavRow(1)}
        {renderNavRow(2)}
      </Flex>
    </Box>
  )
}

export default Footer

const query = graphql`
  query FooterMenuQuery {
    menu: settingsJson {
      primary: footerItemsPrimary {
        title
        uri
        hide
      }
      secondary: footerItemsSecondary {
        title
        uri
        hide
      }
    }
  }
`
