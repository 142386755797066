/** @jsx jsx */
import { jsx, Flex, Box } from "theme-ui"

import Footer from "../components/Footer"
import CookieBanner from "../components/CookieBanner"
import Helmet from "react-helmet"

const Layout = ({ children, title }) => {
  return (
    <Box sx={{ width: "100%", overflowX: "hidden", position: "relative" }}>
      <CookieBanner />
      <Helmet title={title} defer={false} />
      <Flex
        sx={{
          minHeight: "100vh !important",
          position: "relative !important",
          flexDirection: "column",
        }}
      >
        <Flex
          mx="auto"
          px={[3]}
          sx={{
            flex: "1 1 0%",
            flexDirection: "column",
            maxWidth: 900,
            width: "100%",
          }}
        >
          {children}
        </Flex>
        <Box>
          <Footer />
        </Box>
      </Flex>
    </Box>
  )
}

export default Layout
