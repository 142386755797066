/** @jsx jsx */
import { useCallback, useState, useEffect } from "react"
import { jsx, Box, Flex, Text, Button } from "theme-ui"
import { Link } from "gatsby"
import { useLocation } from "@reach/router"
import { initializeAndTrack } from "gatsby-plugin-gdpr-cookies"

import { useHasMounted } from "../../hooks/useHasMounted"

import { PRIVACY } from "../../constants/navigation"

const cookieKey = "c_agree"

function Banner() {
  const hasMounted = useHasMounted()
  const location = useLocation()
  const [privAgree, setPrivAgree] = useState(false)

  useEffect(() => {
    const agree = window.localStorage.getItem(cookieKey)
    if (agree) {
      setPrivAgree(agree)
      initializeAndTrack(location)
    }
  }, [])

  const handleOnAgree = useCallback(() => {
    window.localStorage[cookieKey] = true
    initializeAndTrack(location)
    setPrivAgree(true)
  }, [location])

  if (!hasMounted) return null

  if (privAgree) return null
  return (
    <Box
      id="cookie-banner-root"
      p={3}
      mx={[2, null, "auto"]}
      sx={{
        position: "fixed",
        left: [0, null, "auto"],
        right: [0, null, 24],
        bottom: 24,
        backgroundColor: "secondaryBg",
        zIndex: 1100,
        borderRadius: 8,
        boxShadow: "rgb(0 0 0 / 10%) 0px 12px 24px",
        color: "text",
        maxWidth: ["100%", null, "75%", "50%"],
      }}
    >
      <Flex sx={{ alignItems: "center", justifyContent: "space-between" }}>
        <Flex sx={{ alignItems: "center", flex: "1 0 0%" }}>
          <Text>
            We use cookies to provide you with the best experience.{" "}
            <Link to={PRIVACY}>Learn more</Link>
          </Text>
        </Flex>
        <Flex ml={3} sx={{ alignItems: "center", flexShrink: "0%" }}>
          <Button onClick={handleOnAgree}>I agree</Button>
        </Flex>
      </Flex>
    </Box>
  )
}

export default Banner
